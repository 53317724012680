import React, { useEffect, useState } from 'react';
import {
  BooleanInput,
  NumberInput,
  required,
  TextInput,
  TopToolbar,
  useGetOne,
  Edit,
  useRecordContext,
  SaveButton,
  Form
} from 'react-admin';
import {
  disableOfferByUuid,
  enableOfferByUuid,
  disableOfferIndexByUuid,
  enableOfferIndexByUuid,
  patchOffersByUuid,
  maskOfferByUuid
} from '../../routeApi/offer';

import { useNotify, useRedirect } from 'ra-core';
import { Button, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import BlurOff from '@mui/icons-material/BlurOff';
import { useAuthenticator } from '@aws-amplify/ui-react';
import CategorySelectors from './components/CategorySelectors';
import { getGroups } from '../../utils/CognitoHelpers';
import Modal from '../../components/modal/index';

const EditTitle = ({ record }) => {
  return <span>Editer une offre - {record ? `${record.title}` : ''}</span>;
};

const DisableButton = ({ jwtToken, record }) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const handleClick = () => {
    if (record?.enabled) {
      disableOfferByUuid(record['@id'])
        .then(() => {
          notify("L'offre a bien ete desactive", { type: "info" });
          redirect('/offers');
        })
        .catch(() => {
          notify("Error: impossible de desactiver l'offre", { type: 'warning' })
        })
        .finally(() => {

        });
    } else {
      enableOfferByUuid(record['@id'])
        .then(() => {
          notify("L'offre a bien été activée", { type: "info" });
          redirect('/offers');
        })
        .catch(() => {
          notify("Error: impossible d'activer l'offre", { type: 'warning' })
        })
        .finally(() => {

        });
    }
  };

  const disableIndex = (jwtToken) => {
    disableOfferIndexByUuid(record.uuid, jwtToken)
      .then(() => {
        notify("L'offre a bien ete désindéxée", { type: "info" });
        redirect('/offers');
      })
      .catch(() => {
        notify("Error: impossible de désindexer l'offre", { type: 'warning' })
      })
      .finally(() => {

      });
  };

  const enableIndex = (jwtToken) => {
    enableOfferIndexByUuid(record?.uuid, jwtToken)
      .then(() => {
        notify('L\'offre a bien été indéxée', { type: "info" });
        redirect('/offers');
      })
      .catch(() => {
        notify("Error: impossible d'indexer l'offre", { type: 'warning' })
      })
      .finally(() => { });
  };

  const onClickMaskOffer = (record) => {
    const payload = {
      id: record.uuid,
    }
    maskOfferByUuid(payload, jwtToken)
      .then((rep) => {
        record?.replaysTotalCount > 0 ?
          notify('L\'offre et les replays qui lui sont associés ont bien été masqués', { type: "info" })
          :
          notify('L\'offre a bien été masquée', { type: "info" });
        redirect('/offers');
      })
      .catch(() => {
        notify("Error: impossible de masquer l'offre", { type: 'warning' })
      })
      .finally(() => { });
  }

  const maskOffer = () => {
    let payload;
    let status = '';
    if (record?.status === "published") {
      payload = {
        id: record.uuid,
        status: 'masked'
      }
      status = 'masquée'
    } else {
      payload = {
        id: record.uuid,
        status: 'published'
      }
      status = 'démasquée'
    }
    patchOffersByUuid(payload)
      .then((rep) => {
        if (rep.status !== 200) {
          notify(`Error: impossible de ${status} l\'offre`, { type: 'warning' })
        } else {
          notify(`L\'offre a bien été ${status}`, { type: "info" });
        }
        redirect('/offers');
      })
  };

  return <TopToolbar>
    {
      (record?.status === "published") ?
        <>
          {record?.replaysTotalCount > 0 &&
            <Box sx={{
              border: '1px solid black',
              borderRadius: '25px',
              width: '25px',
              position: 'relative',
              justifyContent: 'center',
              cursor: 'pointer',
              display: 'flex',
              ':hover': {
                position: 'relative',
              }
            }}>
              ?
              <Box sx={{
                opacity: '0',
                position: 'absolute',
                right: '100%',
                zIndex: '1',
                ':hover': {
                  opacity: '1',
                }
              }}>
                La dépublication d'une offre avec des replays associés
                <br /> entrainera la dépublication de ces replays.
              </Box>
            </Box>
          }
          <Button startIcon={<BlurOff />} variant="outlined" color="primary" onClick={() => onClickMaskOffer(record)}>
            Dépublier
          </Button>
        </>
        :
        <Button startIcon={<BlurOff />} variant="outlined" color="primary" onClick={(event) => maskOffer()}>Publier</Button>
    }
    {
      (record?.enabled) ?
        <Button startIcon={<DeleteIcon />} variant="outlined" color="primary" onClick={handleClick}>Désactiver</Button>
        :
        <Button startIcon={<DeleteIcon />} variant="outlined" color="primary" onClick={handleClick}>Activer</Button>
    }
    {
      (record?.is_indexed) ?
        <Button startIcon={<SearchOffIcon />} variant="outlined" color="primary" onClick={() => disableIndex(jwtToken)}>Désindexer</Button>
        :
        <Button startIcon={<SearchOffIcon />} variant="outlined" color="primary" onClick={() => enableIndex(jwtToken)}>Indexer</Button>
    }
  </TopToolbar>;
};


const EditOffers = (({ classes, ...props }) => {
  const [record, setRecord] = useState(null);
  const [categoriesData, setCategoriesData] = useState(null);
  const { user } = useAuthenticator(context => [context.authStatus]);
  const jwtToken = user.signInUserSession.accessToken.jwtToken;
  const { data: offer } = useGetOne('categories', { id: props?.id });

  const transform = data => {
    delete data.offer_picture_galleries;

    data.category = '/categories/' + categoriesData.categorySelectedId;
    data.offer_domain = '/domains/' + categoriesData.domainSelectedId;
    data.difficulty_levels = categoriesData.levelsIds.map((level) => '/difficulty_levels/' + level);
    return data;
  };

  const RecordGetter = ({ onRecord }) => {
    const record = useRecordContext();
    useEffect(() => {
      onRecord(record)
    }, [record]);
  };

  return (
    <Edit {...props} undoable={false} title={<EditTitle />} transform={transform}>
      {!!record && <DisableButton record={record} jwtToken={jwtToken} />}
      <RecordGetter onRecord={setRecord} />
      <Form className={'offerEditForm'}>
        <TextInput
          source="title"
          label="Nom de l'offre"
          name={"title"}
          validate={required()}
          fullWidth
        />
        <TextInput
          source="description"
          name={"description"}
          label="Description de l'offre (Au programme)"
          validate={required()}
          multiline
          fullWidth
        />
        <TextInput
          name={"methodology"}
          source="methodology"
          label="Méthodologie"
          multiline
          fullWidth
        />
        <NumberInput
          name={"price_ht"}
          source="price_ht"
          label="prix de l'offre"
          fullWidth
        />
        <CategorySelectors
          record={record}
          jwtToken={jwtToken}
          categoriesData={categoriesData}
          setCategoriesData={setCategoriesData}
        />
        <BooleanInput
          source="pro_category"
          label="Expert dans sa catégorie"
          fullWidth
        />
        <BooleanInput source={"first_free"} label="Première séance gratuite" fullWidth />
        <BooleanInput
          source="is_highlighted"
          label="Offre mise en avant"
          fullWidth
        />
        <BooleanInput source={"is_indexed"} label="Offre indexée" fullWidth />
        <BooleanInput source={"is_displayed_on_homepage"} label="Offre affichable sur la homepage" fullWidth />
        <SaveButton alwaysEnable />
      </Form>
    </Edit>
  );
});

export default EditOffers;
