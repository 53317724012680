import React from 'react';
import {
  TextInput,
  Filter,
  DateInput,
  SelectField,
  DateField,
  useRecordContext,
  BulkExportButton,
  NumberField,
  List,
  TextField,
  Datagrid, EditButton, ShowButton
} from 'react-admin';

import { statusChoices } from "../utils";
import ListOffers from "../offers/ListOffers";
import {Link} from "@mui/material";

const UrlFieldWooskill = ( props ) =>
{
    const { source, target, rel } = props;
    const record = useRecordContext(props);
    const value = record && record[source];

    if (value === null) {
      return null;
    }

    return (
      <Link href={`${process.env.REACT_APP_LINK}${value}`} target={target} rel={rel}>
        {value}
      </Link>
    );
};

const ProfilesGridFilters = props => (
  <Filter {...props}>
    <TextInput label="Nom" source="name"/>
    <TextInput label="Url" source="slug"/>
    <TextInput label="Id" source="id"/>
    <DateInput
      source="createdAt[after]"
      label="Date de création (Début)"
      options={{
        mode: 'portrait',
        locale: 'nl',
      }}
    />
    <DateInput
      source="createdAt[before]"
      label="Date de création (Fin)"
      defaultValue={props.date}
      options={{
        mode: 'portrait',
        locale: 'nl',
        defaultDate: props.date,
      }}
    />
  </Filter>
);

const PostBulkActionButtons = props => (
  <>
    <BulkExportButton {...props} />
  </>
);

const ListProfiles = (({ ...props}) => {
  const ShowOffers = ({ id }) => (
    <ListOffers
      filter={{ profile_uuid: id }}
      resource="offers"
      pagination={false}
      filters={<ProfilesGridFilters date={new Date()}/>}
      title="offres"
      exporter={false}
    />
  );

  return (
    <List
      filters={<ProfilesGridFilters date={new Date()}/>}
      bulkActionButtons={<PostBulkActionButtons />}
      title="Les profils"
      expand={<ShowOffers />}
      {...props}
    >
      <Datagrid>
        <NumberField label={'Id'} source={'db_id'}/>
        <TextField label={'Nom'} source={'name'}/>
        <DateField
          label="Date de création"
          source={'created_at'}
          showTime
          locales="fr-FR"
        />
        <NumberField label={'Nombre d\'offres'} source={'nb_offers'}/>
        <NumberField label={'Score Hubspot'} source={'score_hubspot'}/>
        <UrlFieldWooskill
          source={'url'}
          target='_blank' // New window
          rel="noopener noreferrer" // For security
        />
        <SelectField source="status" choices={statusChoices}/>
        <>
          <EditButton />
          <ShowButton />
        </>
      </Datagrid>
    </List>
  );
});

export default (ListProfiles);