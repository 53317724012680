import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNotify, useRedirect } from "ra-core";
import {
  TextInput,
  TopToolbar,
  Edit,
  BooleanInput,
  useRecordContext,
  SaveButton,
  Form
} from "react-admin";
import './profile.css';

import { Button } from "@mui/material";
import {
  disableProfileByUuid,
  enableProfileByUuid,
  disableProfileIndexByUuid,
  enableProfileIndexByUuid,
} from "../../routeApi/profiles";
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { useParams } from 'react-router-dom';
import StatuSelector from './components/StatuSelector';

const EditTitle = ({ record }) => {
  return <span>Editer un profil - {record ? `${record.title}` : ''}</span>;
};

const DisableButton = ({ props, data }) => {

  const redirect = useRedirect();
  const notify = useNotify();
  const handleClick = () => {
    if (data?.status === "enabled") {
      disableProfileByUuid(props.id)
        .then(() => {
          notify('Le profil a bien été desactivé', { type: "info" });
          redirect('/profiles');
        })
        .catch((e) => {
          notify('Error: impossible de desactiver le profil', { type: 'warning' })
        })
        .finally(() => {
        });
    } else {
      enableProfileByUuid(props.id)
        .then(() => {
          notify('Le profil a bien été activé', { type: "info" });
          redirect('/profiles');
        })
        .catch((e) => {
          notify('Error: impossible d\'activer le profil', { type: 'warning' })
        })
        .finally(() => {
        });
    }
  };

  const indexClick = () => {
    if (data?.is_indexed) {
      disableProfileIndexByUuid(props.id)
        .then(() => {
          notify('Le profil a bien ete désindéxé', { type: "info" });
          redirect('/profiles');
        })
        .catch((e) => {
          notify('Error: impossible de désindexer le profil', { type: 'warning' })
        })
        .finally(() => {
        });
    } else {
      enableProfileIndexByUuid(props.id)
        .then(() => {
          notify('Le profil a bien été indéxé', { type: "info" });
          redirect('/profiles');
        })
        .catch((e) => {
          notify('Error: impossible d\'indexer le profil', { type: 'warning' })
        })
        .finally(() => {
        });
    }

  };

  return <TopToolbar>
    {
      (data?.status === "enabled") ?
        <Button startIcon={<DeleteIcon />} variant="outlined" color="primary" onClick={handleClick}>Désactiver</Button>
        :
        <Button variant="outlined" color="primary" onClick={handleClick}>Activer</Button>
    }
    {
      (data?.is_indexed) ?
        <Button startIcon={<SearchOffIcon />} variant="outlined" color="primary" onClick={indexClick}>Désindéxer</Button>
        :
        <Button startIcon={<SearchOffIcon />} variant="outlined" color="primary" onClick={indexClick}>Indéxer</Button>
    }
  </TopToolbar>;
};

const EditProfiles = (({ classes, ...props }) => {
  const { id } = useParams();
  const [record, setRecord] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const RecordGetter = ({onRecord}) => {
    const record = useRecordContext();
    useEffect(() => {
      onRecord(record)
    }, [record]);
  };

  const transform = (data) => {
    delete data.courses;
    data.company.status = selectedStatus;
    return data;
  };

  return (
    <Edit
      {...props}
      actions={<DisableButton props={props} />}
      title={<EditTitle />}
      transform={transform}
    >
      <Form className={'profileEditForm'}>
      <RecordGetter onRecord={setRecord} />
      <TextInput label={"Nom du profil"} source={'name'} name={"name"} />
      <TextInput multiline label={"Titre de présentation"} source={'presentation_title'} name={"presentation_title"}/>
      <TextInput multiline label={"Description de présentation"} source={'presentation_description'} name={"presentation_description"}/>
      <BooleanInput label={"Profile mis en avant"} source={'highlighted'} name={"highlighted"}/>
      <BooleanInput label={"Skiller vérifié"} source={'verified_profile'} name={"verified_profile"}/>
      <BooleanInput label={"Skiller influenceur"} source={'is_influencer'} name={"is_influencer"}/>
      <BooleanInput label={"Suppression de frais"} source={'no_fees'} name={"no_fees"} />
     <StatuSelector
       record={record}
       selectedStatus={selectedStatus}
       setSelectedStatus={setSelectedStatus}
     />
    <SaveButton alwaysEnable />
    </Form>
    </Edit>
  );
});

export default EditProfiles;
